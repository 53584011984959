const aboutButton = document.querySelector(".about__button");
const aboutSection = document.querySelector("#about");
const collabButton = document.querySelector(".collab__button");
const collabSection = document.querySelector("#collaborators");
const portfolioButton = document.querySelector(".portfolio__button");
const portfolioSection = document.querySelector("#portfolio");
const contactButton = document.querySelector(".contact__button");
const contactSection = document.querySelector("#contact");
const scrollButton = document.querySelector(".scroll-section__arrow-text");
const logo = document.querySelector(".logo");
const pageLoadOverlay = document.querySelector(".page-load-overlay");
const sliderButton = document.querySelector(".js-portfolio__arrow");