function loadIn() {
    //get els
    const loadinEls = gsap.utils.toArray(".load-in"),
        //set options
        duration = 0.8,
        fromDistance = 0,
        easing = Power4.easeInOut;

    ScrollTrigger.batch(loadinEls, {
        scroller: window,
        once: true,
        onEnter: (batch) =>
            gsap.fromTo(
                batch,
                {
                    y: fromDistance,
                    autoAlpha: 0
                },
                {
                    duration: duration,
                    autoAlpha: 1,
                    y: 0,
                    stagger: 0.05,
                    overwrite: true,
                    ease: easing                    
                }
            )
    });
}
