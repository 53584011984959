// Codekit -->

// @codekit-prepend "components/load-in.js"

// @codekit-prepend "utilities/vars.js"

//<- Codekit

//Funcitons start -->

loadIn();

// // set 100vh for mob and tba

const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--app-height", `${window.innerHeight}px`);
};

window.addEventListener("resize", appHeight);
appHeight();

// slider

var elem = document.querySelector(".main-carousel");
var flkty = new Flickity(elem, {
    // options
    cellAlign: "left",
    contain: true,
    draggable: true,
    wrapAround: true,
    prevNextButtons: false,
    pageDots: false,
    imagesLoaded: true
});

sliderButton.addEventListener("click", function () {
    flkty.next();
});

// animation

var tl = gsap.timeline({ delay: 2 });

function setClassesAfterAnimation() {
    logo.classList.remove("pulsing-animation");
    pageLoadOverlay.classList.add("overlay-remove");
}

function mobile() {
    const mql = window.matchMedia("(min-width: 300px) and (max-width: 899px)");
    checkMedia(mql);
    function checkMedia(mql) {
        if (mql.matches) {
            tl.to(pageLoadOverlay, {
                height: "0%",
                top: "0px",
                left: 1,
                duration: 1.2,
                ease: Power4.easeInOut,
                onComplete: setClassesAfterAnimation
            }),
                0;
            aboutButton.addEventListener("click", () => {
                gsap.to(window, {
                    scrollTo: {
                        duration: 0.6,
                        y: aboutSection,
                        offsetY: 80,
                        ease: Power4.easeInOut
                    }
                });
            });
            collabButton.addEventListener("click", () => {
                gsap.to(window, {
                    scrollTo: {
                        duration: 0.6,
                        y: collabSection,
                        offsetY: 80,
                        ease: Power4.easeInOut
                    }
                });
            });
            portfolioButton.addEventListener("click", () => {
                gsap.to(window, {
                    scrollTo: {
                        duration: 0.6,
                        y: portfolioSection,
                        offsetY: 80,
                        ease: Power4.easeInOut
                    }
                });
            });
            contactButton.addEventListener("click", () => {
                gsap.to(window, {
                    scrollTo: {
                        duration: 0.6,
                        y: contactSection,
                        offsetY: 80,
                        ease: Power4.easeInOut
                    }
                });
            });
            // scrollButton.addEventListener("click", () => {
            //     gsap.to(window, {
            //         scrollTo: {
            //             duration: 0.6,
            //             y: collabSection,
            //             offsetY: 80,
            //             ease: Power4.easeInOut
            //         }
            //     });
            // });
        }
    }
}
mobile();

function tablet() {
    const mql = window.matchMedia("(min-width: 900px) and (max-width: 1199px)");
    checkMedia(mql);
    function checkMedia(mql) {
        if (mql.matches) {
            tl.to(
                pageLoadOverlay,
                {
                    width: "33%",
                    duration: 1.2,
                    transformOrigin: "right",
                    ease: Power4.easeInOut,
                    onComplete: setClassesAfterAnimation
                },
                0
            );
            tl.to(
                logo,
                {
                    left: "1rem",
                    duration: 1.2,
                    transformOrigin: "right",
                    ease: Power4.easeInOut,
                    onComplete: setClassesAfterAnimation
                },
                0
            );
            aboutButton.addEventListener("click", () => {
                gsap.to(window, {
                    scrollTo: {
                        duration: 0.6,
                        y: aboutSection,
                        ease: Power4.easeInOut
                    }
                });
            });
            collabButton.addEventListener("click", () => {
                gsap.to(window, {
                    scrollTo: {
                        duration: 0.6,
                        y: collabSection,
                        ease: Power4.easeInOut
                    }
                });
            });
            portfolioButton.addEventListener("click", () => {
                gsap.to(window, {
                    scrollTo: {
                        duration: 0.6,
                        y: portfolioSection,
                        ease: Power4.easeInOut
                    }
                });
            });
            contactButton.addEventListener("click", () => {
                gsap.to(window, {
                    scrollTo: {
                        duration: 0.6,
                        y: contactSection,
                        ease: Power4.easeInOut
                    }
                });
            });
            scrollButton.addEventListener("click", () => {
                gsap.to(window, {
                    scrollTo: {
                        duration: 0.6,
                        y: collabSection,
                        ease: Power4.easeInOut
                    }
                });
            });
        }
    }
}
tablet();

function desktop() {
    const mql = window.matchMedia("(min-width: 1200px)");
    checkMedia(mql);
    function checkMedia(mql) {
        if (mql.matches) {
            tl.to(
                pageLoadOverlay,
                {
                    width: "40%",
                    duration: 1.2,
                    transformOrigin: "right",
                    ease: Power4.easeInOut,
                    onComplete: setClassesAfterAnimation
                },
                0
            );
            tl.to(
                logo,
                {
                    left: "1rem",
                    duration: 1.2,
                    transformOrigin: "right",
                    ease: Power4.easeInOut,
                    onComplete: setClassesAfterAnimation
                },
                0
            );
            aboutButton.addEventListener("click", () => {
                gsap.to(window, {
                    scrollTo: {
                        duration: 0.6,
                        y: aboutSection,
                        ease: Power4.easeInOut
                    }
                });
            });
            collabButton.addEventListener("click", () => {
                gsap.to(window, {
                    scrollTo: {
                        duration: 0.6,
                        y: collabSection,
                        ease: Power4.easeInOut
                    }
                });
            });
            portfolioButton.addEventListener("click", () => {
                gsap.to(window, {
                    scrollTo: {
                        duration: 0.6,
                        y: portfolioSection,
                        ease: Power4.easeInOut
                    }
                });
            });
            contactButton.addEventListener("click", () => {
                gsap.to(window, {
                    scrollTo: {
                        duration: 0.6,
                        y: contactSection,
                        ease: Power4.easeInOut
                    }
                });
            });
            scrollButton.addEventListener("click", () => {
                gsap.to(window, {
                    scrollTo: {
                        duration: 0.6,
                        y: collabSection,
                        ease: Power4.easeInOut
                    }
                });
            });
        }
    }
}
desktop();

tl.to([".scroll-content", ".fixed-content"], {
    autoAlpha: 1,
    duration: 1.2,
    ease: Power4.easeInOut
});

// //<-- Funcitons end
